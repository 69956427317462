import { Card, Divider } from '@tremor/react'
import { Brain, HandCoins, Landmark, UserPlus } from 'lucide-react'
import { Link } from 'react-router'

import { Button } from '#app/components/ui/button.tsx'
import { type Route } from './+types/index.ts'
// import { logos } from './logos/logos.ts'

export const meta: Route.MetaFunction = () => [{ title: 'Intermave' }]

export default function Index() {
	return (
		<main className="mb-6 mt-6 px-3 md:px-12">
			<div className="relative mx-auto flex min-h-[calc(100vh-192px)] items-center justify-center rounded-3xl bg-neutral-900 px-6 py-16">
				<div className="flex flex-col gap-12">
					<h1 className="mx-auto max-w-6xl animate-slide-top text-6xl font-normal leading-tight text-white [animation-delay:0.2s] [animation-fill-mode:backwards] md:text-center md:text-6xl">
						Meet your next
						<div className="font-serif italic md:mt-3">
							customer / investor / advisor / employee
						</div>
					</h1>

					<h3 className="mx-auto max-w-2xl animate-slide-top text-xl font-light tracking-wide text-white opacity-75 [animation-delay:0.3s] [animation-fill-mode:backwards] md:text-center md:text-2xl">
						Intermave helps founders build genuine professional relationships by
						sharing their professional goals with those who can help achieve
						them.
						{/* Intermave helps professionals build genuine 1-on-1 professional
						relationships by matching their skills with real business problems. */}
						{/* Intermave connects founders with expert advisors —
						<span className="italic">at zero cost</span>— to achieve their
						business goals. */}
					</h3>

					<div>
						<div className="animate-slide-top [animation-delay:0.4s] [animation-fill-mode:backwards] md:flex md:justify-center">
							{/* <TextInput placeholder="" />
							<Button variant="outline">Claim</Button> */}
							<Link to="https://tally.so/r/wbvdxe">
								<Button variant="outline" size="lg" className="w-full">
									Join for free
								</Button>
							</Link>
							{/* <Link to="/learn">
								<Button variant="link" size="lg" className="dark">
									Learn more
								</Button>
							</Link> */}
						</div>
						{/* <div className="mt-3 text-center">
							<Link
								to="/learn"
								className="animate-slide-top text-sm font-light italic tracking-wide text-white underline-offset-2 opacity-75 [animation-delay:0.4s] [animation-fill-mode:backwards] hover:underline"
							>
								How is this different than LinkedIn?
							</Link>
						</div> */}
						<div className="mt-6 flex justify-center">
							<YCombinator />
						</div>
					</div>

					{/* <div className="mt-3 hidden animate-slide-top [animation-delay:0.8s] [animation-fill-mode:backwards] md:block">
						<div className="flex items-center justify-center gap-8 opacity-70">
							<span className="text-xs text-white">Trusted by:</span>
							{logos.map((logo) => (
								<img
									key={logo.alt}
									src={logo.src}
									alt={logo.alt}
									width={50}
									height={50}
								/>
							))}
							<img
								src="https://placehold.co/50x50"
								alt="P&G"
								width={50}
								height={50}
							/>
							<img
								src="https://placehold.co/50x50"
								alt="PayPal"
								width={50}
								height={50}
							/>
							<img
								src="https://placehold.co/50x50"
								alt="Payoneer"
								width={50}
								height={50}
							/>
						</div>
					</div> */}
				</div>
			</div>
			{/* <Activities /> */}
		</main>
	)
}

export function Activities() {
	return (
		<div className="mx-auto my-8 flex max-w-7xl flex-wrap justify-center gap-6">
			{[
				{ icon: UserPlus, titles: ['Recruit', 'Apply'] },
				{ icon: HandCoins, titles: ['Sell', 'Buy'] },
				{ icon: Landmark, titles: ['Fundraise', 'Invest'] },
				{ icon: Brain, titles: ['Consult', 'Learn'] },
			].map((item, i) => (
				<Card
					key={i}
					className="flex min-h-32 w-36 items-center justify-center drop-shadow-md hover:drop-shadow-lg"
				>
					<div className="flex w-full flex-col gap-2">
						<item.icon className="mx-auto h-8 w-8" />
						<div className="mt-2 w-full text-center">
							{item.titles.map((title, index) => (
								<>
									<div className="text-sm" key={title}>
										{title}
									</div>
									{item.titles.length - 1 !== index && (
										<Divider className="mb-0.5 mt-1.5 text-xs">or</Divider>
									)}
								</>
							))}
						</div>
					</div>
				</Card>
			))}
		</div>
	)
}

function YCombinator() {
	return (
		<div className="flex animate-slide-top flex-col gap-y-1.5 [animation-delay:0.6s] [animation-fill-mode:backwards]">
			<div className="dark text-center text-xs uppercase tracking-wide text-white opacity-50">
				Backed by
			</div>
			<span className="flex items-center gap-1.5 text-sm tracking-wider text-white">
				<img src="/img/yc-dark.svg" alt="YC logo" className="h-5 w-5" />
				<span className="caption text-center">Combinator</span>
			</span>
		</div>
	)
}
